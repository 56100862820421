<script lang="ts" setup>
import { Pagination } from 'swiper/modules'
import ButtonNavigation from './ButtonNavigation.vue'

interface Props {
    banners: any[]
    title: string
    textBlockBackgroundImage: string
    reverse?: boolean
    showSubTitle?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = withDefaults(defineProps<Props>(), {
    showSubTitle: true
})

const animationDuration = 30
const animationDurationK = 0.05
const autoplaySpeed = (animationDuration + animationDurationK) * 1000

const currentSlideIndex = ref(0)
const { width } = useWindowResize()
const isMobile = computed(() => width?.value < 768)
const options = computed(() => ({
    modules: [Pagination],
    autoplay: false,
    autoplaySpeed,
    arrows: false,
    infinity: false,
    dots: isMobile.value,
    pagination: isMobile.value,
    spaceBetween: 100
}))

const swiperSlider = ref(null)
const onSwiper = swiper => {
    swiperSlider.value = swiper
}
const nextSlide = () => {
    swiperSlider.value.slideNext()
    currentSlideIndex.value = swiperSlider.value.activeIndex
}
const prevSlide = () => {
    swiperSlider.value.slidePrev()
    currentSlideIndex.value = swiperSlider.value.activeIndex
}
const changSlide = () => {
    currentSlideIndex.value = swiperSlider.value.activeIndex
}
const { localeProperties } = useI18n()

const textInnerWrapperId = useId()
const textInnerWrapperEl = ref()

const paginationStyles = ref({
    left: `${textInnerWrapperEl.value?.offsetLeft || 0}px`
})
const nuxtApp = useNuxtApp()

onMounted(async () => {
    await nextTick()
    textInnerWrapperEl.value = document.querySelector(
        `#${textInnerWrapperId}`
    ) as HTMLElement
    initTextInnerWrapperHtmlElement()
    updatePaginationStyles()
})

nuxtApp.hook('page:transition:finish', () => {
    initTextInnerWrapperHtmlElement('page:transition:finish')
})
// nuxtApp.hook('page:transition:finish', () => {
//     initTextInnerWrapperHtmlElement('page:transition:finish')
// })

const { width: widowSize } = useWindowResize()
watch(
    () => widowSize,
    () => {
        initTextInnerWrapperHtmlElement('resize')
    },
    { immediate: true, deep: true }
)

function initTextInnerWrapperHtmlElement() {
    if (textInnerWrapperEl.value) return

    textInnerWrapperEl.value = document.querySelector(
        `#${textInnerWrapperId}`
    ) as HTMLElement

    paginationStyles.value = {
        left: `${textInnerWrapperEl.value?.offsetLeft}px`
    }
}

function updatePaginationStyles() {
    if (textInnerWrapperEl.value) {
        paginationStyles.value = {
            left: `${textInnerWrapperEl.value.offsetLeft}px`
        }
    }
}

console.dir(textInnerWrapperEl.value?.offsetLeft)
// const paginationLeftPosition = computed(
//     () => textInnerWrapperEl?.offsetLeft || 0
// )
// const paginationStyles = computed(() => {
//     return { left: `${paginationLeftPosition.value}px` }
// })
</script>

<template>
    <div
        :class="[
            $style.wrapper,
            { [$style['reverse-slider']]: reverse }
        ]"
    >
        <NuxtImg
            :class="$style['text-wrapper__image']"
            :src="textBlockBackgroundImage"
            loading="lazy"
            alt="icon"
        />
        <Swiper
            v-bind="{ ...options, ...$attrs }"
            :dir="localeProperties.dir"
            @slide-change="changSlide"
            @swiper="onSwiper"
        >
            <SwiperSlide
                v-for="(banner, index) in banners"
                :key="index"
            >
                <div
                    :class="[$style['slide-content']]"
                    :style="{
                        flexDirection:
                            (reverse && $i18n.locale !== 'ar') ||
                            (!reverse && $i18n.locale === 'ar')
                                ? 'row-reverse'
                                : 'row'
                    }"
                >
                    <div :class="$style['content-wrapper']">
                        <NuxtImg
                            v-if="banner.imageLink"
                            :class="$style.image"
                            :src="banner.imageLink"
                            alt="banner"
                            loading="lazy"
                        />
                    </div>

                    <div
                        :class="$style['text-wrapper']"
                        :style="{
                            paddingRight:
                                reverse && $i18n.locale === 'ar'
                                    ? '25px'
                                    : '0'
                        }"
                        class="home-page__slide-text-wrapper"
                    >
                        <div
                            :id="textInnerWrapperId"
                            :class="$style['text-inner-wrapper']"
                        >
                            <AppNuxtLinkLocale
                                tag="h3"
                                :to="
                                    banner.toTitle
                                        ? {
                                              path: banner.toTitle,
                                              hash: banner.hash
                                          }
                                        : banner.to
                                "
                                :class="[
                                    $style.title,
                                    { [$style.titleReverse]: reverse }
                                ]"
                            >
                                {{ title }}
                            </AppNuxtLinkLocale>
                            <AppNuxtLinkLocale
                                v-if="showSubTitle"
                                tag="p"
                                :to="banner.to"
                                :class="$style['outer-text-title']"
                            >
                                {{ banner.title }}
                            </AppNuxtLinkLocale>

                            <AppNuxtLinkLocale
                                :to="banner.to"
                                :class="$style['outer-text-desc']"
                            >
                                {{ banner.shortDescription }}
                            </AppNuxtLinkLocale>
                            <AppNuxtLinkLocale
                                :class="[
                                    $style['outer-text-readmore']
                                ]"
                                :style="{}"
                                :to="banner.to"
                                >{{ $t('global.rmore') }}
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.8334 15.625C10.7513 15.6254 10.67 15.6094 10.5941 15.5779C10.5183 15.5464 10.4495 15.5001 10.3918 15.4417C10.2747 15.3245 10.209 15.1657 10.209 15C10.209 14.8344 10.2747 14.6756 10.3918 14.5584L14.9501 10L10.3918 5.4417C10.2814 5.32322 10.2213 5.16652 10.2241 5.0046C10.227 4.84268 10.2926 4.68819 10.4071 4.57368C10.5216 4.45917 10.6761 4.39358 10.838 4.39072C10.9999 4.38787 11.1566 4.44797 11.2751 4.55837L16.2751 9.55837C16.3921 9.67556 16.4579 9.83441 16.4579 10C16.4579 10.1657 16.3921 10.3245 16.2751 10.4417L11.2751 15.4417C11.2174 15.5001 11.1486 15.5464 11.0728 15.5779C10.9969 15.6094 10.9156 15.6254 10.8334 15.625Z"
                                        fill="#3B82F6"
                                    />
                                    <path
                                        d="M15.8337 10.625H4.16699C4.00123 10.625 3.84226 10.5592 3.72505 10.4419C3.60784 10.3247 3.54199 10.1658 3.54199 10C3.54199 9.83424 3.60784 9.67527 3.72505 9.55806C3.84226 9.44085 4.00123 9.375 4.16699 9.375H15.8337C15.9994 9.375 16.1584 9.44085 16.2756 9.55806C16.3928 9.67527 16.4587 9.83424 16.4587 10C16.4587 10.1658 16.3928 10.3247 16.2756 10.4419C16.1584 10.5592 15.9994 10.625 15.8337 10.625Z"
                                        fill="#3B82F6"
                                    />
                                </svg>
                            </AppNuxtLinkLocale>
                            <div
                                :class="$style['hidden-pagination']"
                            ></div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
        <AppNuxtLinkLocale
            v-if="banners.length && !isMobile"
            :class="[
                $style['outer-text-readmore--mobile'],
                $style['outer-text-readmore']
            ]"
            :to="banners[currentSlideIndex].to"
            >{{ $t('global.rmore') }}
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.8334 15.625C10.7513 15.6254 10.67 15.6094 10.5941 15.5779C10.5183 15.5464 10.4495 15.5001 10.3918 15.4417C10.2747 15.3245 10.209 15.1657 10.209 15C10.209 14.8344 10.2747 14.6756 10.3918 14.5584L14.9501 10L10.3918 5.4417C10.2814 5.32322 10.2213 5.16652 10.2241 5.0046C10.227 4.84268 10.2926 4.68819 10.4071 4.57368C10.5216 4.45917 10.6761 4.39358 10.838 4.39072C10.9999 4.38787 11.1566 4.44797 11.2751 4.55837L16.2751 9.55837C16.3921 9.67556 16.4579 9.83441 16.4579 10C16.4579 10.1657 16.3921 10.3245 16.2751 10.4417L11.2751 15.4417C11.2174 15.5001 11.1486 15.5464 11.0728 15.5779C10.9969 15.6094 10.9156 15.6254 10.8334 15.625Z"
                    fill="#3B82F6"
                />
                <path
                    d="M15.8337 10.625H4.16699C4.00123 10.625 3.84226 10.5592 3.72505 10.4419C3.60784 10.3247 3.54199 10.1658 3.54199 10C3.54199 9.83424 3.60784 9.67527 3.72505 9.55806C3.84226 9.44085 4.00123 9.375 4.16699 9.375H15.8337C15.9994 9.375 16.1584 9.44085 16.2756 9.55806C16.3928 9.67527 16.4587 9.83424 16.4587 10C16.4587 10.1658 16.3928 10.3247 16.2756 10.4419C16.1584 10.5592 15.9994 10.625 15.8337 10.625Z"
                    fill="#3B82F6"
                />
            </svg>
        </AppNuxtLinkLocale>
        <div
            v-if="!isMobile"
            :class="$style.pagination"
            :style="paginationStyles"
        >
            <div
                v-if="banners.length >= 2"
                :class="$style['pagination__counter']"
                class="pagination-counter"
            >
                <ButtonNavigation dir="right" @click="prevSlide" />
                <ButtonNavigation dir="left" @click="nextSlide" />
            </div>
            <div
                v-if="banners.length >= 2"
                :class="$style['pagination__list']"
            >
                <span
                    v-for="(_, index) in banners"
                    :key="index"
                    :class="[
                        $style['page'],
                        {
                            [$style['page-active']]:
                                currentSlideIndex === index
                        }
                    ]"
                ></span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
$slider-height: 625px;

.wrapper {
    width: 100%;
    position: relative;
    max-height: $slider-height;
    // padding: 0 15px;

    @include md-block() {
        max-height: 600px;
    }

    & :global(.swiper-pagination) {
        top: 285px !important;
        height: 20px;

        :global(.swiper-pagination-bullet) {
            background-color: #ffffff;
            opacity: 1;
        }

        :global(.swiper-pagination-bullet-active) {
            background-color: #3b82f6;
        }
    }
}

.content-wrapper {
    position: relative;
    min-width: 50%;
    height: $slider-height;
    display: flex;
    justify-content: center;

    @include md-block() {
        height: 500px;
    }

    @include sm-block() {
        margin-bottom: 26px;
    }
    @include xs-block() {
        height: 320px;
    }

    :global(.container) {
        position: absolute;
        top: 3rem;
        left: 50%;
        transform: translate(-50%, -1rem);
        z-index: $zi-bigest;
        margin: auto;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.slide-content {
    display: flex;
    width: 100%;
    position: relative;

    @include sm-block() {
        flex-direction: column !important;
        padding-bottom: 100px;
    }
}

.image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    filter: brightness(0.7);
}

.title-wrapper {
    overflow: hidden;
    max-width: 910px;
    position: relative;
    z-index: $zi-bigest;
}

.title {
    font-weight: 700;
    @include adaptive-value('font-size', 36, 20, 1);
    line-height: 1.5;
    cursor: pointer;
    // margin-left: 14%;
    margin-right: 0;

    @media screen and (max-width: 640px) {
        margin-left: 0;
    }

    &:hover {
        color: #3b82f6;
        text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.2);
    }

    @include sm-block() {
        position: absolute;
        top: 16px;
        left: 10px;
        color: #fff;
        // background-color: $main-color;
        padding: 0px 3px;
        line-height: 100%;
        padding: 5px 12px;
        border-radius: 4px;
        font-size: 20px;
        // @include adaptive-value('font-size', 40, 20, 1);
    }
}

.titleReverse {
    // margin-right: 16%;
    margin-left: 0;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: $slider-height;
    @include adaptive-value('padding-left', 25, 0, 1);

    @include md-block() {
        height: 500px;
    }

    @include sm-block() {
        width: 100%;
        padding-left: 0;
        height: auto;
        padding-bottom: 30px;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
}

.outer-text-title {
    max-width: 90%;
    font-style: normal;
    font-weight: 600;
    @include adaptive-value('font-size', 16, 16, 1);
    line-height: 1.3;
    margin-top: 10px;

    @include sm-block() {
        order: 1;
        max-width: none;
        padding: 0 0 0 10px;
    }
    // padding-left: 80px;
    padding-right: 0;

    &:hover {
        color: #3b82f6;
        text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.2);
    }
}

.outer-text-desc {
    max-width: 567px;
    line-height: 1.5;
    @include adaptive-value('font-size', 16, 14, 1);
    @include adaptive-value('margin-top', 20, 10, 1);
    // padding-left: 80px;
    padding-right: 0;
    z-index: $zi-bigest;
    @include sm-block() {
        max-width: none;
        order: 3;
        padding: 0 0 0 10px;
        margin-bottom: 16px;
    }
}

.outer-text-readmore {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    max-width: max-content;
    min-width: 140px;
    min-height: 36px;
    border-radius: 24px;
    font-weight: 500;
    padding: 10px 15px;
    @include adaptive-value('font-size', 16, 14, 1);
    background-color: #fff;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    color: #3b82f6;
    text-align: left;
    margin-top: 38px;
    z-index: $zi-bigest;
    @include sm-block() {
        display: none;
        order: 3;
        margin-top: 24px;
        padding: 0 15px 0 10px;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
        margin-left: 8px;
    }

    &:hover {
        color: #fff;
        background-color: #3b82f6;

        svg {
            path {
                fill: #fff;
            }
        }

        // text-decoration: underline;
    }
}

.text-inner-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 550px;
    max-width: 560px;
    @include lg-block {
        min-width: 420px;
    }
    @include md-block {
        min-width: 380px;
    }
    @include xs-block {
        min-width: 320px;
    }
}

.pagination {
    position: absolute;
    z-index: $zi-bigest;
    bottom: 40px;
    left: calc(50% + 24px);
    height: 5px;
    max-width: 375px;
    min-width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    @include adaptive-value('font-size', 32, 14, 1);

    gap: 15px;

    &__list {
        width: 100%;
        display: flex;
    }

    .page {
        transition: all 0.3s linear;
        flex: 1;
        background-color: rgba(217, 217, 217, 1);
        height: 5px;
    }

    .page-active {
        width: 100%;
        display: inline-block;
        height: 5px;
        background-color: #3b82f6;
    }

    &__counter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }

    &__counter-number {
        display: inline-block;
        letter-spacing: 0.3em;
        @include adaptive-value('margin-left', 25, 10, 1);
        @include adaptive-value('margin-right', 25, 10, 1);
    }
}

.hidden-pagination {
    width: 100%;
    @include adaptive-value('height', 80, 50, 1);
    margin-top: 25px;

    @include sm-block() {
        order: 4;
        display: none;
    }
}

.angle-left,
.angle-right {
    cursor: pointer;
}

.reverse-slider {
    .pagination {
        left: 0;
        align-items: flex-start;

        @include sm-block() {
            align-items: flex-end;
            left: auto;
            right: 15px;
        }
    }

    & :global(.slick-dots) {
        left: 50%;
    }

    .text-wrapper {
        background-position:
            top right,
            bottom right;
        padding-left: 25px;
        @include sm-block() {
            padding: 0;
        }
    }

    .title {
        padding-left: 0;

        @include sm-block() {
            padding: 5px 12px;
            border-radius: 4px;
        }
    }

    .outer-text-desc,
    .outer-text-title {
        padding-right: 80px;
        padding-left: 0;
        display: inline-flex;
        @include sm-block() {
            padding: 0 8px;
        }
    }

    .outer-text-readmore {
        @include sm-block() {
            display: none;
        }
    }

    .outer-text-desc {
        overflow: hidden;
        text-overflow: ellipsis;
        @include md-block() {
            max-height: 180px;
        }
        @include sm-block() {
            max-height: unset;
        }
    }

    .dots-right,
    .dots-left {
        display: none !important;
    }
}

.outer-text-readmore--mobile {
    display: none !important;
    position: absolute;
    bottom: 22px;
    @include sm-block() {
        display: flex !important;
        z-index: $zi-bigest;
    }
}

@keyframes statusAnimation {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
</style>
<style lang="scss">
.home-page {
    @include sm-block() {
        & > .container {
            padding: 0;
        }
    }
}
</style>
